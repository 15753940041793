import React from 'react';
import { useTranslation } from 'react-i18next';
import AOS from "aos"
import 'aos/dist/aos.css'
import { useEffect } from 'react';

const HowItWorks = ({language}) => {
    const { t } = useTranslation();

    useEffect(()=>{
        AOS.init({duration: 2000})
      }, [])

    return (
        <section className="how" data-aos="zoom-in-up">
            <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
                <div className="max-w-2xl mx-auto text-center">
                    <h2 className=" text-3xl font-bold leading-tight text-primary sm:text-4xl lg:text-5xl" style={language === 'En' ? {fontFamily: "Poppins-Regular"} : {fontFamily: "tkt"}}>{t('How does it work?')}</h2>
                    <p className="max-w-lg mx-auto mt-4 text-base leading-relaxed text-gray-600" style={language === 'En' ? {fontFamily: "Poppins-Regular"} : {fontFamily: "tkt"}}>{t('Scan, discover, and make informed choices about your food instantly with GETchmeva.')}</p>
                </div>

                <div className="relative mt-12 lg:mt-20">
                    <div className="absolute inset-x-0 hidden xl:px-44 top-2 md:block md:px-20 lg:px-28">
                        <img className="w-full" src="https://cdn.rareblocks.xyz/collection/celebration/images/steps/2/curved-dotted-line.svg" alt="" />
                    </div>

                    <div className="relative grid grid-cols-1 text-center gap-y-12 md:grid-cols-3 gap-x-12">
                        <div className="col-span-1">
                            <div className="flex items-center justify-center w-16 h-16 mx-auto bg-white border-2 border-gray-200 rounded-full shadow">
                                <span className="text-xl font-semibold text-gray-700"> 1 </span>
                            </div>
                            <h3 className="mt-6 text-xl font-semibold leading-tight text-black md:mt-10" style={language === 'En' ? {fontFamily: "Poppins-Regular"} : {fontFamily: "tkt"}}>{t('Create a free account')}</h3>
                            <p className="mt-4 text-base text-gray-600" style={language === 'En' ? {fontFamily: "Poppins-Regular"} : {fontFamily: "tkt"}}> {t("Join the GETchmeva community for free and access a world of comprehensive food insights at your fingertips.")}</p>
                        </div>

                        <div className="col-span-1">
                            <div className="flex items-center justify-center w-16 h-16 mx-auto bg-white border-2 border-gray-200 rounded-full shadow">
                                <span className="text-xl font-semibold text-gray-700"> 2 </span>
                            </div>
                            <h3 className="mt-6 text-xl font-semibold leading-tight text-black md:mt-10" style={language === 'En' ? {fontFamily: "Poppins-Regular"} : {fontFamily: "tkt"}}>{t("Scan the product")}</h3>
                            <p className="mt-4 text-base text-gray-600" style={language === 'En' ? {fontFamily: "Poppins-Regular"} : {fontFamily: "tkt"}}>{t("Empower your choices — SCAN your products to get instant information.")}</p>
                        </div>

                        <div className="col-span-1">
                            <div className="flex items-center justify-center w-16 h-16 mx-auto bg-white border-2 border-gray-200 rounded-full shadow">
                                <span className="text-xl font-semibold text-gray-700"> 3 </span>
                            </div>
                            <h3 className="mt-6 text-xl font-semibold leading-tight text-black md:mt-10" style={language === 'En' ? {fontFamily: "Poppins-Regular"} : {fontFamily: "tkt"}}>{t("Enjoy your meal")}</h3>
                            <p className="mt-4 text-base text-gray-600" style={language === 'En' ? {fontFamily: "Poppins-Regular"} : {fontFamily: "tkt"}}>{t("Bon appétit! Enjoy your meal with the added delight of GETchmeva, enhancing your dining experience with informed choices.")}</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default HowItWorks;
