import React, { useState } from "react"; // Import useState
import Navbar from "./Navbar";
import HowItWorks from "./HowItWorks";
import Testimonials from "./Testimonials";
import NotifySection from "./Notify";
import Footer from "./Footer";
import ProductCard from "./ProductCard";
import iphone from "../assets/iPhone.svg";
import appstore from "../assets/tempAppstore.svg";
import android from "../assets/tempAppstore2.svg";
import { useTranslation } from "react-i18next";
import i18n from "../i18next";

export default function Home() {
  const { t } = useTranslation();
  const [language, setLanguage] = useState("En"); // Define state and setState

  const changeLanguageFunction = () => {
    const newLanguage = language === "En" ? "Ka" : "En";
    i18n.changeLanguage(newLanguage);
    setLanguage(newLanguage); // Update language state
  };

  return (
    <>
      <Navbar 
        changeLanguageFunction={changeLanguageFunction}
        language={language}
      />
      <div className="main">
        <div className="hero">
          <div className="md:px-20">
            <h1
              className="custom-heading"
              style={
                language === "En"
                  ? { fontFamily: "Poppins-Regular" }
                  : { fontFamily: "tkt" }
              }
            >
              <span
                className="text-primary"
                style={{ fontFamily: "Poppins-Regular" }}
              >
                GETchmeva{" "}
              </span>
              {t("your personal grocery assistant")}
            </h1>
            <p
              className="mt-5 text-1.5xl text-black"
              style={
                language === "En"
                  ? { fontFamily: "Poppins-Regular" }
                  : { fontFamily: "tkt" }
              }
            >
              {t("Available soon on these platforms!")}
            </p>
            <div className="download">
              <img
                src={appstore}
                alt="App Store"
                className="md:w-44 md:h-auto sm:w-40 sm:h-auto cursor-default"
              />
              <img
                src={android}
                alt="Google Play"
                className="md:w-44 md:h-auto sm:w-40 sm:h-auto cursor-default"
              />
            </div>
          </div>
          <img src={iphone} alt="iPhone" className="levitating-img" />
        </div>

        {/* Additional sections within Home */}
        <HowItWorks language={language} />
        <ProductCard language={language} />
        <Testimonials language={language} />
        <NotifySection language={language} />
        <Footer />
      </div>
    </>
  );
}
