import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationEN from './translation/en/global.json';
import translationKA from './translation/ka/global.json';

// the translations
const resources = {
  En: {
    translation: translationEN
  },
  Ka: {
    translation: translationKA
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: "en",
    fallbackLng: "en",

    keySeparator: false,

    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
