import React, { useState } from 'react';
import AOS from "aos"
import 'aos/dist/aos.css'
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const Faq = ({language}) => {
    const { t } = useTranslation();

    useEffect(()=>{
        AOS.init({duration: 2000})
      }, [])

    const [faq, setFaq] = useState([
        {
            question: 'How to create an account?',
            answer: "Making an account is a breeze! Just type in your name, email, password, and dietary info. Boom! You're good to go!",
            open: false
        },
        {
            question: 'Where can I use your application?',
            answer: 'Our partnet compnays will revealed soon and there you can use our application',
            open: false
        },
        {
            question: 'Who is this product made for?',
            answer: 'For anyone curious about what they eat!',
            open: false
        },
        {
            question: 'How can I reach to support?',
            answer: 'Drop us a message we are ready to help any time! getchmevams@gmail.com',
            open: false
        }
    ]);

    const toggleFaq = (index) => {
        setFaq(faq.map((item, i) => {
            if (i === index) {
                item.open = !item.open;
            } else {
                item.open = false;
            }

            return item;
        }));
    }

    return (
        <section className="py-10 bg-gray-50 sm:py-16 lg:py-24">
            <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl" data-aos="zoom-in-up">
                <div className="max-w-2xl mx-auto text-center">
                    <h2 style={language === 'En' ? {fontFamily: "Poppins-Regular"} : {fontFamily: "tkt"}} className="text-3xl font-bold leading-tight text-black sm:text-4xl lg:text-5xl">{t("Frequently Asked Questions")}</h2>
                    <p style={language === 'En' ? {fontFamily: "Poppins-Regular"} : {fontFamily: "tkt"}} className="max-w-xl mx-auto mt-4 text-base leading-relaxed text-gray-600">{t("Explore answers to common queries and concerns below")}</p>
                </div>

                <div className="max-w-3xl mx-auto mt-8 space-y-4 md:mt-16">
                    {faq.map((item, index) => (
                        <div key={index} className="transition-all duration-200 bg-white border border-gray-200 cursor-pointer hover:bg-gray-50">
                            <button type="button" className="flex items-center justify-between w-full px-4 py-5 sm:p-6" onClick={() => toggleFaq(index)}>
                                <span style={language === 'En' ? {fontFamily: "Poppins-Regular"} : {fontFamily: "tkt"}} className="flex text-lg font-semibold text-black">{t(item.question)}</span>

                                <svg className={`w-6 h-6 text-gray-400 ${item.open ? 'rotate-180' : ''}`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                                </svg>
                            </button>

                            <div className={`${item.open ? 'block' : 'hidden'} px-4 pb-5 sm:px-6 sm:pb-6`}>
                                <p style={language === 'En' ? {fontFamily: "Poppins-Regular"} : {fontFamily: "tkt"}} dangerouslySetInnerHTML={{ __html: t(item.answer) }}></p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}

export default Faq;
