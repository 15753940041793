import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import ButtonWrapper from './ButtonWrapper';
import AOS from "aos"
import 'aos/dist/aos.css'
import { useEffect } from 'react';

const Notify = ({language}) => {
  const { t } = useTranslation();

  const scriptURL = "https://script.google.com/macros/s/AKfycbyrDhicLUgqD5JKSvy-TQ-hlpTJ_FLNsMUO1_YtbeNnIIhwYsrj3IVWOpNJIVDBC5V7/exec";
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(()=>{
    AOS.init({duration: 2000})
  }, [])

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!isValidEmail(email)) {
      setErrorMessage("Please enter a valid email address!");
      setErrorMessageColor("red");
      return;
    }

    setErrorMessage('');

    try {
      const response = await fetch(scriptURL, { 
        method: "POST", 
        body: new FormData(document.forms["submit-to-google-sheet"]) 
      });

      if (response.ok) {
        setErrorMessage("Thank you for subscribing!");
        setErrorMessageColor("green");
      } else {
        console.error("Error!", response);
        setErrorMessage("An error occurred. Please try again later.");
        setErrorMessageColor("red");
      }
    } catch (error) {
      console.error("Error!", error.message);
      setErrorMessage("An error occurred. Please try again later.");
      setErrorMessageColor("red");
    }
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/;
    return emailRegex.test(email.toLowerCase());
  };

  const setErrorMessageColor = (color) => {
    document.getElementById("error-message").style.color = color;
  };

  return (
    <section className="home4" data-aos="zoom-in-up">
      <div className="notify">
        <div className="wraper">
          <div className="box">
            <h1 style={
                language === "En"
                  ? { fontFamily: "Poppins-Regular" }
                  : { fontFamily: "tkt" }
              }>{t("Get notified when we’re launching")}</h1>
            <form id="notifyForm" name="submit-to-google-sheet" onSubmit={handleSubmit}>
              <div className="email" id="email">
                <input
                className='shadow-[0px_0px_40px_rgba(255,_255,_255,_0.8),_5px_5px_10px_rgba(0,_0,_0,_0.25)]'
                  type="email"
                  name="Email"
                  id="emailInput"
                  placeholder={t("Enter your email")}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                  style={
                    language === "En"
                      ? { fontFamily: "Poppins-Regular" }
                      : { fontFamily: "tkt" }
                    }
                />
                {/* <button type="submit" style={
                    language === "En"
                      ? { fontFamily: "Poppins-Regular" }
                      : { fontFamily: "Poppins-Regular" }
                    }><ButtonWrapper/></button> */}
                    <ButtonWrapper type="submit"/>
              </div>
            </form>
            <div id="error-message">
              {errorMessage}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Notify;
