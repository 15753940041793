import React from "react";
import { useTranslation } from "react-i18next";
import AOS from "aos"
import 'aos/dist/aos.css'
import { useEffect } from 'react';

const ProductCard = ({ language }) => {
  const { t } = useTranslation();

  useEffect(()=>{
    AOS.init({duration: 2000})
  }, [])

  return (
    <div className="product_card" data-aos="zoom-in-up">
      <div className="pr_card">
        <div className="fafa_container">
          <div className="fafa"></div>
          <div className="fafa_shadow"></div>
          <div className="pop-up">
            <div className="green-circle"></div>
            <h5
              className="veg-txt"
              style={
                language === "En"
                  ? { fontFamily: "Poppins-Regular" }
                  : { fontFamily: "tkt" }
              }
            >
              {t("Sugar Free")}
            </h5>
          </div>
        </div>
      </div>
      <div className="pr_text">
        <h1
          style={
            language === "En"
              ? { fontFamily: "Poppins-Regular" }
              : { fontFamily: "tkt" }
          }
        >
          {t("Evaluate the quality of your foods")}
        </h1>
        <h2 style={
            language === "En"
              ? { fontFamily: "Poppins-Regular" }
              : { fontFamily: "tkt" }
          }>
          {t(
            "Do you really know what you're buying? We do! GETchmeva scans and analyzes labels in the blink of an eye so you can learn at a glance which products are good for you and which ones you should avoid."
          )}
        </h2>
      </div>
    </div>
  );
};

export default ProductCard;
