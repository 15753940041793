import { FiSend } from "react-icons/fi";

const ButtonWrapper = () => {
  return (
    <div className="flex items-center justify-center">
      <NeumorphismButton />
    </div>
  );
};

const NeumorphismButton = () => {
  return (
    <button
      className={`
        px-6 py-3 rounded-md bg-white 
        flex items-center gap-2 
        text-slate-500
        shadow-[0px_0px_40px_rgba(255,_255,_255,_0.8),_5px_5px_10px_rgba(0,_0,_0,_0.25)]
        
        transition-all

        hover:shadow-[-1px_-1px_5px_rgba(255,_255,_255,_0.6),_1px_1px_5px_rgba(0,_0,_0,_0.3),inset_-2px_-2px_5px_rgba(255,_255,_255,_1),inset_2px_2px_4px_rgba(0,_0,_0,_0.3)]
        hover:text-primary
    `}
    >
      <FiSend />
      <span>Submit</span>
    </button>
  );
};

export default ButtonWrapper;