import React from 'react';
import { useTranslation } from 'react-i18next';
import AOS from "aos"
import 'aos/dist/aos.css'
import { useEffect } from 'react';

const Vis = ({language}) => {
  const { t } = useTranslation();

  useEffect(()=>{
    AOS.init({duration: 2000})
  }, [])

  return (
    <div className="our-vision">
      <h5 data-aos="zoom-in-up" style={language === 'En' ? {fontFamily: "Poppins-Regular"} : {fontFamily: "tkt"}}>{t("Our Vision")}</h5>
      <h6 className='w-3/4' style={language === 'En' ? {fontFamily: "Poppins-Regular"} : {fontFamily: "tkt"}} data-aos="zoom-in-up">{t("We want everyone to have instant access to product ingredients, nutritional value and compatibility with their diet. By doing so, we will have a healthier and more informed society.")}</h6>

      <div className="team_members_count" data-aos="zoom-in-up">
        <h5 style={language === 'En' ? {fontFamily: "Poppins-Regular"} : {fontFamily: "tkt"}} >{t("Our Values")}</h5>
        <h6 className='w-3/4' style={language === 'En' ? {fontFamily: "Poppins-Regular"} : {fontFamily: "tkt"}} >
          <span className="font-bold ">{t("Transparency")} </span>{t("We believe in providing you with comprehensive information about the product, its compatibility with your health and desires.")}
        </h6>
        <h6 className='w-3/4' style={language === 'En' ? {fontFamily: "Poppins-Regular"} : {fontFamily: "tkt"}}>
          <span className="font-bold">{t("Customer Satisfaction")}</span> {t("Your satisfaction is our priority. We are committed to providing excellent service, solving your problems and constantly helping you make the right choice.")}
        </h6>
      </div>
    </div>
  );
};

export default Vis;
