import logo from "../assets/Logo.svg";
import React, { useState } from "react";
import geo from "../assets/GEO.png";
import eng from "../assets/ENG.png";
import { FaTimes } from "react-icons/fa";
import { HiOutlineMenuAlt3 } from "react-icons/hi";

import { useTranslation } from "react-i18next";

import { Link } from "react-router-dom";

const Navbar = ({ changeLanguageFunction, language }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { t } = useTranslation();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  // const navItems = [
  //   { link: {t(Home)}, path: "home" },
  //   { link: "About Us", path: "about us" },
  // ];

  return (
    <>
      <nav className="px-8 py-3 items-center border-b border-gray-200  mx-auto bg-white sticky top-0 right-0 left-0">
        <div className="flex mx-auto items-center justify-between">
          <a href="/" className="flex items-center text-2xl space-x-3">
            <img
              src={logo}
              alt=""
              srcSet=""
              className="w-10 inline-block items-center cursor-default"
            />{" "}
            <div className="lg:hidden md:hidden items-center text-center text-2xl text-primary font-semibold">
              GETchmeva
            </div>
          </a>

          <ul className="flex space-x-7 text-1.5xl sm:hidden md:flex">
            {/* {navItems.map((item) => (
              <li key={item.path}>
                <a
                  href={item.path}
                  className="block text-1xl nav-link transition-all"
                >
                  {item.link}
                </a>
              </li>
            ))} */}
            <li className="flex gap-5">
              {/* <a
                href="#"
                className="text-1.5xl nav-link transition-all"
                style={
                  language === "En"
                    ? { fontFamily: "Poppins-Regular" }
                    : { fontFamily: "tkt" }
                }
              >
                {t("About Us")}
              </a> */}
              <Link
                to="/"
                className="text-1.5xl nav-link transition-all"
                style={
                  language === "En"
                    ? { fontFamily: "Poppins-Regular" }
                    : { fontFamily: "tkt" }
                }
              >
                {t("Home")}
              </Link>
              <Link
                to="/about"
                className="text-1.5xl nav-link transition-all"
                style={
                  language === "En"
                    ? { fontFamily: "Poppins-Regular" }
                    : { fontFamily: "tkt" }
                }
              >
                {t("About Us")}
              </Link>
              <Link
                to="/scan"
                className="text-1.5xl nav-link transition-all"
                style={
                  language === "En"
                    ? { fontFamily: "Poppins-Regular" }
                    : { fontFamily: "tkt" }
                }
              >
                {t("Scan")}
              </Link>
            </li>
          </ul>

          <button
            onClick={() => changeLanguageFunction()}
            className="px-2 py-3 sm:hidden md:flex custom-rounded border border-gray-200 cursor-pointer"
          >
            <img src={language === "En" ? geo : eng} alt="" className="w-6" />
          </button>

          <div className="md:hidden">
            <button onClick={toggleMenu} className="text-tartiary text-3xl">
              {isMenuOpen ? <FaTimes /> : <HiOutlineMenuAlt3 />}
            </button>
          </div>
        </div>
      </nav>

      <div
        className={`leftNav bg-primary space-y-4 px-4 pt-32 pb-10 md:hidden ${
          isMenuOpen ? "active" : ""
        }`}
      >
        <ul
          className={`burgerNav text-2.5xl w-full h-full flex flex-col pr-4 `}
        >
          {/* {navItems.map((item) => (
            <li key={item.path} className="mb-4">
              <a href={item.path} className=" text-1xl block transition-all">
                {item.link}
              </a>
            </li>
          ))} */}
          <li className="flex flex-col gap-4">
            <Link
              to="/"
              className="text-1.5xl text-white transition-all"
              style={
                language === "En"
                  ? { fontFamily: "Poppins-Regular" }
                  : { fontFamily: "tkt" }
              }
            >
              {t("Home")}
            </Link>
            <Link
              to="/about"
              className="text-1.5xl text-white transition-all"
              style={
                language === "En"
                  ? { fontFamily: "Poppins-Regular" }
                  : { fontFamily: "tkt" }
              }
            >
              {t("About Us")}
            </Link>
            <Link
              to="/scan"
              className="text-1.5xl text-white transition-all"
              style={
                language === "En"
                  ? { fontFamily: "Poppins-Regular" }
                  : { fontFamily: "tkt" }
              }
            >
              {t("Scan")}
            </Link>
          </li>
          <div className="w-100% h-0.5 bg-white"></div>
          <button
            onClick={() => changeLanguageFunction()}
            className="flex items-center cursor-pointer"
          >
            <img
              src={language === "En" ? geo : eng}
              alt=""
              className="w-7 mr-2"
            />
            <span>{language === "En" ? "GEO" : "ENG"}</span>
          </button>
        </ul>
      </div>
    </>
  );
};

export default Navbar;
