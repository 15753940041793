import "./App.css";
import Home from "./components/Home";
import { useState } from "react";
import i18n from "./i18next";
import React from "react";
import About from "./components/About";
import { Route, Routes, useLocation } from "react-router-dom";
import { useEffect } from "react";
import ScanMain from "./components/ScanMain";

function App() {
  const [language, setLanguage] = useState("En");
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const path = location.pathname;

  const changeLanguageFunction = () => {
    console.log("language changed");
    language === "En" ? i18n.changeLanguage("Ka") : i18n.changeLanguage("En");
    language === "En" ? setLanguage("Ka") : setLanguage("En");
    console.log(language);
  };

  useEffect(() => {
    // Scroll to the top whenever the route changes
    window.scrollTo(0, 0);
    setIsMenuOpen(false);
  }, [location.pathname]);

  return (
    <>
      <Routes>
        <Route path="/" element={<Home language={language} />} />
        <Route path="/about" element={<About language={language} />} />
        <Route path="/scan" element={<ScanMain language={language} />} />
      </Routes>
    </>
  );
}

export default App;
