import React, { useState, useEffect } from "react"; // Import useState and useEffect
import img1 from "../assets/2.jpeg";
import { useTranslation } from "react-i18next";
import AOS from "aos";
import "aos/dist/aos.css";
import Faq from "./FAQ";
import Vis from "./Vision";
import Team from "./Team";
import Footer from "./Footer";
import Navbar from "./Navbar";
import i18n from "../i18next";

export default function About() {
  const { t } = useTranslation();
  const [language, setLanguage] = useState("En"); // Define language state

  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  // Define changeLanguageFunction within the component
  const changeLanguageFunction = () => {
    const newLanguage = language === "En" ? "Ka" : "En";
    i18n.changeLanguage(newLanguage);
    setLanguage(newLanguage); // Update language state
  };

  return (
    <>
      <Navbar 
        changeLanguageFunction={changeLanguageFunction} // Pass function to Navbar
        language={language}
      />
      <div className="aboutMain">
        <div className="custom-div" data-aos="zoom-in-up">
          <h2 className="text-primary font-semibold text-2.5xl">GETchmeva</h2>
          <h1
            className="text-black font-bold"
            style={
              language === "En"
                ? { fontFamily: "Poppins-Regular" }
                : { fontFamily: "tkt" }
            }
          >
            {t("Our Journey")}
          </h1>
          <p
            style={
              language === "En"
                ? { fontFamily: "Poppins-Regular" }
                : { fontFamily: "tkt" }
            }
          >
            {t(
              "Our team united around a common goal in 2023 - we wanted to create a convenient and technically sound application to help people who need help choosing food products. Despite being on different continents, the team remains committed to the goal and will offer you a customized product very soon."
            )}
          </p>
        </div>
        <img src={img1} className="custom-img" data-aos="zoom-in-up" />
      </div>
      <Vis language={language} />
      <Team language={language} />
      <Faq language={language} />
      <Footer />
    </>
  );
}
